
.mainClassTechnologies{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;
}

@media screen and (min-width: 480px) {
    .mainClassTechnologies{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);

    }
  }

.titleTechnologyName{
    font-family: emoji;
    font-size: 19px;
    font-weight: 700;
    color: #2f89c5;
    margin-bottom: 2px;
}

@media screen and (min-width: 480px) {
    .titleTechnologyName{
        font-family: cursive;
    }
  }

.titleTechnologies{
    color: #890b38;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 13px;   
}

@media screen and (min-width: 480px) {
    .titleTechnologies{
        margin-bottom: 15px;   
    }
}

.imageTechnology{
    height: 50vh;
}

@media screen and (min-width: 480px) {
    .imageTechnology {
        height: 100vh;
    }
  }

.textContentTechnologies{
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    margin-top: 0px;
    background: #f9f9f9;
}

@media screen and (min-width: 480px) {
    .textContentTechnologies{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        min-width: 35%;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.technologyMainImageBackground{
    background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .technologyMainImageBackground{
        background: transparent;
    }
}

.technologyContainer{
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.sectionTechnologiesFewParagraph{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    color: brown;
}

@media screen and (min-width: 480px) {
    .sectionTechnologiesFewParagraph{
        font-size: 18px;
    }
}

.reactLogo{
    height: 30px;
    margin-right: 12px;
    margin-left: 10px;
}

.nodeLogo{
    height: 37px;
    margin-right: 10px;
    margin-left: 10px;
}
