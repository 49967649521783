.customNavbar {
    display: none;
  }

  @media screen and (min-width: 480px) {
    .customNavbar {
      overflow: hidden;
      /* background-color: #333; */
      background-color: transparent;
      position: fixed;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  
  .customNavbar a {
    float: left;
    display: block;
    background: #890b38;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .customNavbar a:hover {
    background: #f9f9f9;
    color: black;
  }

  .dropbtn {
    /* background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none; */
    height: 30px;
    margin: 16px;
  }
  
  .dropdown {
    /* position: relative;
    display: inline-block; */

    /* overflow: hidden; */
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: 480px) {
    .dropdown {
      display: none;
    }
  }

  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }

  .customNavBarContainer{
    background-color: transparent;
  }