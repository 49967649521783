
.mainClassHero{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

@media screen and (min-width: 480px) {
    .mainClassHero{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;

    }
  }

.titleAli{
    font-family: cursive;
    font-size: 50px;
    font-weight: 700;
    color: #2f89c5;
}

.imageHero{
    height: 50vh;
}

@media screen and (min-width: 480px) {
    .imageHero {
        height: 100vh;
        margin-left: 100px;
    }
  }

.textContentHero{
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    background: #f9f9f9;
}

@media screen and (min-width: 480px) {
    .textContentHero{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        min-width: 400px;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.getInTouchButtonHero{
    background-color: #e6a800;
    min-height: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 40px;
    border-radius: 8px;
    letter-spacing: 1px;
}

.getInTouchButtonHero:hover {
    background-color: #cc9600;
  }

.heroMainImageBackground{
    background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .heroMainImageBackground{
        background: transparent;
    }
}

.helloIAm{
    color: #890b38;
    font-weight: 700;
    letter-spacing: 2px;
}

.heroLineSoftwareEngineer{
    font-size: 18px;
    color: brown;
}