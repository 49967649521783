.hero-nav-container{

}


@media screen and (min-width: 480px) {
    .hero-nav-container{
        background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
        width: 100%;
    }
}

.coverImage{
width: 100%;
object-fit: contain;
}

@media screen and (min-width: 480px) {
    .coverImage{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        }
}

.coverImageQuiz{
    width: 100%;
    object-fit: contain;
    }
    
    @media screen and (min-width: 480px) {
        .coverImageQuiz{
            width: 100%;
            height: 100vh;
            object-fit: contain;
            }
}
