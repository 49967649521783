
.mainClassFooter{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: transparent;

}

@media screen and (min-width: 480px) {
    .mainClassFooter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100vh;
        background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);

    }
  }

.textContentFooter{
    display: flex;
    flex-direction: column;
    padding: 30px 20px 20px 20px;
    margin-left: 0px;
    background: #f9f9f9;
}

@media screen and (min-width: 480px) {
    .textContentFooter{
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        min-width: 400px;
        padding: 0px 0px;
        margin-top: 0px;
        background: transparent;
    }
}

.sayHelloButtonFooter{
    background-color: #e6a800;
    min-height: 50px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 40px;
    border-radius: 8px;
    letter-spacing: 1.5px;
}

.sayHelloButtonFooter:hover {
    background-color: #cc9600;
}

.socialMediaLinksHeading{
    color: #890b38;
    font-weight: 700;
    letter-spacing: 2px;
}

.socialMediaIconsContainer{
    display: flex;
    margin: 20px 0px;
    justify-content: space-around;
}

.socialMediaIcon{
height: 40px;
}

.socialMediaIcon:hover{
    /* background-color: #890b38; */
    background-color: lightgray;
}

.sectionFooterWelcomeParagraph{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    color: brown;
    padding: 10px 0px;
}

@media screen and (min-width: 480px) {
    .sectionFooterWelcomeParagraph{
        font-size: 18px;
        max-width: 400px;
        line-height: 24px;
        padding: 20px 0px;
    }
}

.sectionFooterDesignedParagraph{
    display: none;
}

@media screen and (min-width: 480px) {
    .sectionFooterDesignedParagraph{
        display: block;
        font-size: 18px;
        max-width: 400px;
        line-height: 24px;
        padding: 30px 0px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #2f89c5;
    }
}

.sectionFooterDesignedParagraphMobile{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
    color: #2f89c5;
    background-color: #f9f9f9;
    padding: 20px 0px;   
}

@media screen and (min-width: 480px) {
    .sectionFooterDesignedParagraphMobile{
        display: none;
    }
}

.sectionFooterDesignedByAli{
    color: #890b38;
    font-weight: 700;
}

.footerMainImageBackground{
    background: linear-gradient(55deg, #f9f9f9 50%, #890b38 50%, #890b38 60%, #f9f9f9 60%, #f9f9f9 70%, #890b38 70%, #890b38 80%, #f9f9f9 80%);
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 480px) {
    .footerMainImageBackground{
        background: transparent;
    }
}

.imageFooter{
    height: 50vh;
}

@media screen and (min-width: 480px) {
    .imageFooter {
        height: 100vh;
    }
  }